import { Dispatch } from "redux";

import { updateAgent } from "./api";
import { ActionType } from "./model";
import { GetState } from "../store.model";

const updateDetails = (specialities, agentId) => async (dispatch: Dispatch, getState: GetState) => {
  await updateAgent(specialities, agentId);
  dispatch({
    type: ActionType.UPDATE_AGENT,
    data: specialities,
  });
};

export { updateDetails };
