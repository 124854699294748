import { CameraSource } from "@capacitor/camera";
import { NotificationPreferencesDocument } from "@src/app/privacyPolicy/notificationPreference/model";
import { Agent, Shift } from "@src/lib/interface";

interface PopupOption {
  visible: boolean;
  header?: string;
  message?: string;
}

export interface Popups {
  ICAgreement?: { visible: boolean; [key: string]: unknown };
  covidTestingPopup?: { visible: boolean; [key: string]: unknown };
  cantAddFriendPopup?: PopupOption;
  userDeniedAccessTo?: { visible: boolean; needAccessTo?: CameraSource };
  profileUpdatePopup?: { visible: boolean; updateType?: "EMAIL" | "PHONE" };
  uploadTimesheetModal?: { visible: boolean; shiftDetailsForUpload?: Shift };
}

// from [api]/envVariables/index.js
export interface SessionEnv {
  disputedTimeDistance: number;
  instantPay: {
    amountPercentage: number;
    gracePeriod: number;
    defaultLunchTime: number;
  };
  facilitySafeDistanceMiles: number;
  PushNotificationOnAccountTab: boolean;
  minToAllowTimeCardUpload: number;
  minBeforeShiftStart: number;
  shiftEndGracePeriod: number;
  callOffWindow: number;
  cancellationHoursMin: number;
  cancellationHoursMax: number;
  notificationPreferences: NotificationPreferencesDocument;
}
export interface Session {
  authStateHasLoaded: boolean;
  isLoggingOut: boolean;
  isVerifying: boolean;
  isAuthorized: boolean;
  isSignup: boolean;
  verification?: {
    phone?: string;
  } | null;
  userId?: string;
  profile?: {
    phone: string;
  };
  agent?: Agent;
  showAlert: boolean;
  sendBirdAccessToken?: string;
  env?: SessionEnv | null;
  bookingRolloutMsas?: Array<string>;
  popups?: Popups;
  referralRate?: string;
  recognizedHoliday?: string;
  isFetchAgentProfileFailureAlertEnabled?: boolean;
  loggedNCIconViewedEvent?: boolean;
}

export enum ActionType {
  VERIFICATION_ID_RECEIVED = "VERIFICATION_ID_RECEIVED",
  VERIFICATION_REQUESTED = "VERIFICATION_REQUESTED",
  SET_IS_VERIFYING_DONE = "SET_IS_VERIFYING_DONE",
  LOGGED_IN = "LOGGED_IN",
  LOGGED_OUT = "LOGGED_OUT",
  LOGOUT_REQUESTED = "LOGOUT_REQUESTED",
  AGENT_PROFILE = "AGENT_PROFILE",
  UPDATE_AGENT = "UPDATE_AGENT",
  CHECKING_AUTHORIZATION = "CHECKING_AUTHORIZATION",
  SET_IS_SIGNUP = "SET_IS_SIGNUP",
  SET_SHOW_ALERT = "SET_SHOW_ALERT",
  SET_ENV_VARIABLES = "SET_ENV_VARIABLES",
  UPDATE_REFERRAL_RATE = "UPDATE_REFERRAL_RATE",
  SHOW_IC_AGREEMENT_POPUP = "SHOW_IC_AGREEMENT_POPUP",
  HIDE_IC_AGREEMENT_POPUP = "HIDE_IC_AGREEMENT_POPUP",
  SHOW_PROFILE_UPDATE_POPUP = "SHOW_PROFILE_UPDATE_POPUP",
  HIDE_PROFILE_UPDATE_POPUP = "HIDE_PROFILE_UPDATE_POPUP",
  SET_RECOGNIZED_HOLIDAY = "SET_RECOGNIZED_HOLIDAY",
  SHOW_FETCH_WORKER_FAILURE_ALERT = "SHOW_FETCH_WORKER_FAILURE_ALERT",
  SHOW_DEEP_LINK_WWF_CANNOT_ADD_FRIEND_ALERT = "SHOW_DEEP_LINK_WWF_CANNOT_ADD_FRIEND_ALERT",
  HIDE_DEEP_LINK_WWF_CANNOT_ADD_FRIEND_ALERT = "HIDE_DEEP_LINK_WWF_CANNOT_ADD_FRIEND_ALERT",
  SHOW_NEED_ACCESS_TO_TOAST = "SHOW_NEED_ACCESS_TO_TOAST",
  HIDE_NEED_ACCESS_TO_TOAST = "HIDE_NEED_ACCESS_TO_TOAST",
  SHOW_UPLOAD_TIMESHEET_MODAL = "SHOW_UPLOAD_TIMESHEET_MODAL",
  HIDE_UPLOAD_TIMESHEET_MODAL = "HIDE_UPLOAD_TIMESHEET_MODAL",
  LOG_NC_ICON_VIEWED_EVENT = "LOG_NC_ICON_VIEWED_EVENT",
}

/**
 * Rename this
 */
export enum LocalStorage {
  AUTH_TOKEN = "authToken",
  AGENT = "agent",
  NEW_PHONE = "newPhoneNumber",
  RECOGNIZED_HOLIDAYS = "recognizedHolidays",
  TRIP_TRACKING_SILENT_PUSH_EXEC_AT = "TRIP_TRACKING_SILENT_PUSH_EXEC_AT",
  SHIFT_TRIP = "SHIFT_TRIP",
}

export interface SessionAction {
  type: string;
  data?: Partial<Session>;
}
