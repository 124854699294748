import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import request from "superagent";

import { getAuthHeader } from "../../superagent";

const updateAgent = async (specialities, agentId): Promise<void> => {
  await request
    .put(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/put`)
    .set(await getAuthHeader())
    .send({ agentId, specialities })
    .catch(logApiFailureEvent);
};

export { updateAgent };
